<template>
  <div class="city" id="city">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'city',
  mounted() {
    this.$store.dispatch("initCityData");
  }
}
import { mapState } from 'vuex'
</script>

<style scoped>
.city {
  width: 100%;
  height: 100%;
}
</style>
